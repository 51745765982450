import ApiServiceNew from "./ApiServiceNew"

export async function apiSignIn(data) {
    return ApiServiceNew.fetchData({
        url: '/gaze_tutor/signin',
        method: 'post',
        data,
    })
}

export async function apiGetAssmentData(uid) {
    return ApiServiceNew.fetchData({
      method: 'get',
      url: `/getScoreData?uid=${uid}`,
      // headers: {
      //   'Content-Type': 'application/octet-stream'
      // }
    });
  }

  export async function courseUpdateService(data) {
    return ApiServiceNew.fetchData({
      method: 'post',
      url: `/updateSharedMessage`,
      data
      // headers: {
      //   'Content-Type': 'application/octet-stream'
      // }
    });
  }

  export async function updateLiveSessionService(data) {
    return ApiServiceNew.fetchData({
      method: 'post',
      url: `/updateLiveSessions`,
      data
      // headers: {
      //   'Content-Type': 'application/octet-stream'
      // }
    });
  }

    export async function sendCourse(data) {
    return ApiServiceNew.fetchData({
      method: 'post',
      url: `/sendSharedCourseUsers`,
      data
      // headers: {
      //   'Content-Type': 'application/octet-stream'
      // }
    });
  }

  export async function apiExportAssessments(uid) {
    return ApiServiceNew.fetchData({
      method: 'get',
      url: `/exportAssessments?uid=${uid}`,
      // headers: {
      //   'Content-Type': 'application/octet-stream'
      // }
    });
  }


export async function apiCreateStudentAndShare(data) {
    
    return ApiServiceNew.fetchData({
        url: '/createShareToStudents',
        method: 'post',
        data,
    })
}


export async function apiSignUpNew(data) {
    
    return ApiServiceNew.fetchData({
        url: '/signUpNew',
        method: 'post',
        data,
    })
}

export async function apiSignOut(data) {
    return ApiServiceNew.fetchData({
        url: '/gaze_tutor/signout',
        method: 'post',
        data,
    })
}



export async function apiForgotPassword(data) {
    return ApiServiceNew.fetchData({
        url: '/gaze_tutor/forgot-password',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data) {
    return ApiServiceNew.fetchData({
        url: '/resetpassword',
        method: 'post',
        data,
    })
}
