import ApiService from './ApiService'
import { useSelector } from 'react-redux'
import UploadFileService from './UploadFileService'
// import YoutubeVideoService from './YoutbeVideoService'
import axios from 'axios'
import ApiCustomService from './ApiCustomService'



///const token = "123456"

//const connectionUrl = `https://d58c2rghs8.execute-api.ap-south-1.amazonaws.com/development/@connections/${token}`;

export async function apiAskUniverse (data) {
    return ApiService.fetchData({
        url: '/send_to_websocket_context',
     //   url: connectionUrl,
        method: 'post',
        data
    })
}





export async function apiUploadFile (data) {
// const response = await axios.post(
//   "https://server.gaze.co.in/image-to-text",
//  // formData,
  
//   {
    
//     headers: {
//       "Content-Type": "multipart/form-data"
//     }
//   }
// );


return axios.post(
  "https://server.gaze.co.in/image-to-text",
  {"file":data},
  
  {
    
    headers: {
      "Content-Type": "multipart/form-data"
    }
  }
);

    // return UploadFileService.fetchData({
    //     url: '/image-to-text',
    //  //   url: connectionUrl,
    //     method: 'post',
    //         headers: {
    //   "Content-Type": "multipart/form-data"
    // },
    //     data
    // })
}

export async function apiStripPayment (data) {
    return ApiService.fetchData({
        url: '/gaze_tutor/create-payment-link',
     //   url: connectionUrl,
        method: 'post',
        data
    })
}

export async function apiSpeechSynthesis (data) {
    return ApiService.fetchData({
        url: '/gaze_tutor/synthesize-cloud',
        //  responseType: 'blob' ,
     //   url: connectionUrl,
        method: 'post',
        data
    })
}


export async function apiStripPaymentCheckoutSession (data) {
    return ApiService.fetchData({
        url: '/gaze_tutor/create-checkout-session',
     //   url: connectionUrl,
        method: 'post',
        data
    })
}


export async function apiDetailUserStoryFromSocket (data) {
    return ApiService.fetchData({
     //   url: '/send_to_websocket',
        url: '/send_to_websocket_context',
   //  url: connectionUrl,
        method: 'post',
        data
    })
}


export async function apiGetYoutubeTranscript (data) {
    return ApiService.fetchData({
     //   url: '/send_to_websocket',
        url: '/get_transcript',
   //  url: connectionUrl,
        method: 'post',
        data
    })
}
export async function apiGetUserStoryCodeFromSocket (data) {
    return ApiService.fetchData({
    //   url: '/send_to_websocket',
      url: '/send_to_websocket_context',
   //  url: connectionUrl,
        method: 'post',
        data
    })
}

export async function apiAskCourseTopics (course_name) {
    return ApiService.fetchData({
        url: '/ask-course-headers/'+course_name,
        method: 'get',
        
    })
}

export async function apiAskCourseDetails (data) {
    return ApiService.fetchData({
        url: '/gaze_tutor/ask-universe-context',
        method: 'post',
        data
        
    })
}

export async function apiNodeServices (data) {
    return ApiCustomService.fetchData({
        url: '/fetchScoreData',
        method: 'post',
        data
        
    })
}

export async function apiGetQuestionsFromPdf (data) {
    return ApiCustomService.fetchData({
        url: '/',
        method: 'post',
        data
        
    })
}

export async function apiAskSprintStories (data) {
    return ApiService.fetchData({
        url: '/gaze_tutor/ask-universe-context',
        method: 'post',
        data
        
    })
}

export async function apiGetUserStories (data) {
    return ApiService.fetchData({
        url: '/gaze_tutor/ask-universe-context',
        method: 'post',
        data
        
    })
}


export async function apiGetAssesment (data) {
    return ApiService.fetchData({
        url: '/gaze_tutor/ask-universe-context',
        method: 'post',
        data
        
    })
}


export async function apiGetPlanForCourse (data) {
    return ApiService.fetchData({
        url: '/gaze_tutor/ask-universe-context',
        method: 'post',
        data
        
    })
}


export async function uploadToGoogleCloud(data) {
    return fetch( data.presignedUrl,
    {
        method: 'PUT',
        body: data.file,
        headers: {
          'Content-Type': 'application/octet-stream'
        }
      })
  
    }


    export async function getSignedUrlGcloud(data) {
        return ApiService.fetchData( data,
        {
            method: 'get',
          //  body: data.file,
            // headers: {
            //   'Content-Type': 'application/octet-stream'
            // }
          })
      
        }
  


// export async function apiGetYoutubeVideos (data) {

//     console.log("Before calling youtve=="+JSON.stringify(data));
//     return YoutubeVideoService.fetchData({
//         url: '/youtube/v3/search',
//         method: 'get',
//         data
        
//     })
// }



// useEffect(() => {
//     const fetchVideos = async () => {
//       try {
//         const response = await axios.get(
//           "https://www.googleapis.com/youtube/v3/search",
//           {
//             params: {
//               part: "snippet",
//               q: searchText, // Replace with your search query
//               maxResults: 4, // Number of videos to display
//               key: "AIzaSyBYAGrfnEz_szVkSa5rRSD03GlDaZ4R_Y0", // Replace with your YouTube API key
//             },
//           }
//     );
//        // setVideos(response.data.items);
//         dispatch(setVideos(response.data.items));
//       } catch (error) {
//         console.error("Error fetching videos:", error);
//       }
//     };

//     fetchVideos();
//   }, [videoId,dispatch]);

// export async function apiGetAssesment (data) {

//    // const { course, topic, level } = data;
//     const course=data.slice(0, 30);
//     const topic = data;
//     const level = 'easy';

//     const url = `/gaze_tutor/ask-course-mcq-admin/${course}/${"1"}/${topic}/${level}`;
//     return ApiService.fetchData({
//         url: url,
//         method: 'get',
        
        
//     })
// }

